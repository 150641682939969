<template>
  <div>
    <span :id="id" :class="{ red: hasExpired }"> {{ item.price | formatNumber }} &#8381; </span>
    <popover :target="id">
      <div>
        <div>
          <b>Ср. стоимость часа (план): </b>
          <span>{{ item['average_cost_plan_hours'] || 0 | formatNumber }} &#8381;</span>
        </div>
        <div>
          <b>Ср. стоимость часа (факт): </b>
          <span>{{ item['average_cost_fact_hours'] || 0 | formatNumber }} &#8381;</span>
        </div>
        <br />
        <div>
          <b>Оплачено: </b>
          <span
            >{{ item['actual_payments_total'] || 0 | formatNumber }} из
            {{ item['payments_total'] | formatNumber }} &#8381;</span
          >
        </div>
        <div v-if="item['payments_duty']">
          <b>Долг: </b>
          <span> {{ item['payments_duty'] || 0 | formatNumber }} &#8381; </span>
        </div>
        <br />
        <div>
          <b>Фактические платежи: </b>
          <ol class="pl-15 m-0" v-if="paid.length">
            <li v-for="payment in paid">
              {{ payment.amount | formatNumber }} &#8381; ({{ payment.date | formatDate }})
            </li>
          </ol>
          <template v-else>-</template>
        </div>
        <br />
        <div>
          <b>Плановые платежи: </b>
          <ol class="pl-15 m-0" v-if="unpaid.length">
            <li v-for="payment in unpaid" :class="{ red: expired(payment) }">
              {{ payment.amount | formatNumber }} &#8381; ({{ payment.date | formatDate }})
            </li>
          </ol>
          <template v-else>-</template>
        </div>
      </div>
    </popover>
  </div>
</template>

<script>
  import uniqid from 'uniqid'

  import Popover from '../../../components/PopoverAlive'

  export default {
    name: 'Budget',
    props: ['item'],
    components: {
      Popover,
    },
    data() {
      return {
        id: uniqid('budget-'),
      }
    },
    computed: {
      payments() {
        return this.item['payments'] || []
      },
      paid() {
        return this.item['actual_payments'] || []
      },
      unpaid() {
        return this.item['future_payments'] || []
      },
      hasExpired() {
        return this.unpaid.some(p => {
          return this.expired(p)
        })
      },
    },
    methods: {
      expired(payment) {
        return Date.now() > Date.parse(payment.date)
      },
    },
  }
</script>
