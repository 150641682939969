<template>
  <div>
    <template v-if="item['leadStatus']">
      <div :id="id">
        <status-tag :color="item['leadStatusColor']" :name="item['leadStatus']" />
      </div>
      <popover :target="id">
        <div style="max-width: 300px">
          <b>Статус проекта: </b>
          <span style="white-space: pre-line">{{ item['project_status_log'] || '-' }}</span>
        </div>
      </popover>
    </template>
    <template v-else>-</template>
  </div>
</template>

<script>
  import uniqid from 'uniqid'

  import StatusTag from '../../../components/LeadStatus'
  import Popover from '../../../components/PopoverAlive'

  export default {
    name: 'LeadStatus',
    props: ['item'],
    components: {
      StatusTag,
      Popover,
    },
    data() {
      return {
        id: uniqid('lead_status-'),
      }
    },
  }
</script>
