<template>
  <div>
    <progress-bar reverse :id="id" :max="150" :value="item.hours_mastering_percent">
      {{ item['employee_statistic_total'] | formatDuration }} /
      {{ item['planHours'] || 0 | formatDuration('h[ч] m[м]', undefined, 'hours') }}
      ({{ item['hours_mastering_percent'] || 0 | formatNumber }}%)
    </progress-bar>
    <popover :target="id">
      <div>
        <div>
          <b>Участники: </b>
          <ul v-if="(item['employee_statistic'] || []).length" class="pl-15 m-0">
            <li v-for="employee in item['employee_statistic']" class="mt-2">
              {{ employee.name }} ({{ employee.seconds | formatDuration }})<br />
              Последнее касание:
              <span
                :title="employee.lastInterval.completed_at | formatDate('DD MMMM YYYY [в] H[:]mm')"
                v-b-tooltip.hover
              >
                {{ employee.lastInterval.completed_at | formatDateRelative }}
              </span>
            </li>
          </ul>
          <template v-else>-</template>
        </div>
      </div>
    </popover>
  </div>
</template>

<script>
  import uniqid from 'uniqid'

  import Popover from '../../../components/PopoverAlive'
  import ProgressBar from '../../../components/ProgressBar'

  export default {
    name: 'Hours',
    props: ['item'],
    components: {
      Popover,
      ProgressBar,
    },
    data() {
      return {
        id: uniqid('hours-'),
      }
    },
  }
</script>

<style scoped></style>
