<template>
  <div>
    <div :id="id" :class="{ red }">{{ item['payments_duty'] || 0 | formatNumber }} &#8381;</div>
    <popover v-if="unpaid.length" :target="id">
      <div>
        <div>
          <b>Оставшиеся платежи: </b><br />
          <ol class="pl-15 m-0">
            <li v-for="payment in unpaid" :class="{ red: expired(payment) }">
              <span> {{ payment.amount | formatNumber }} &#8381; ({{ payment.date | formatDate }}) </span>
            </li>
          </ol>
        </div>
      </div>
    </popover>
  </div>
</template>

<script>
  import uniqid from 'uniqid'

  import Popover from '../../../components/PopoverAlive'

  export default {
    name: 'PaymentsDuty',
    props: ['item'],
    components: {
      Popover,
    },
    data() {
      return {
        id: uniqid('payments_duty-'),
      }
    },
    computed: {
      payments() {
        return this.item['payments'] || []
      },
      unpaid() {
        return this.payments.filter(p => !p.status)
      },
      red() {
        return this.payments
          .filter(p => !p.status)
          .some(p => {
            return this.expired(p)
          })
      },
    },
    methods: {
      expired(payment) {
        return Date.now() > Date.parse(payment.date)
      },
    },
  }
</script>
