<template>
  <b-progress class="m-0" style="position: relative" :style="{ 'min-width': width + 'px' }">
    <b-progress-bar :value="value" :style="{ 'background-color': gradientColor }" />
    <div class="progressbar-text"><slot /></div>
  </b-progress>
</template>

<script>
  import gradient from 'tinygradient'
  import cloneDeep from 'lodash/cloneDeep'

  export default {
    name: 'progress-bar',
    props: {
      value: Number,
      reverse: Boolean,
      max: {
        type: Number,
        default: 100,
      },
      grad: {
        type: Number,
        default: 15,
      },
      width: {
        type: Number,
        default: 150,
      },
    },
    data() {
      return {
        baseColors: ['#dc3545', '#ffc107', '#28a745'],
      }
    },
    computed: {
      colors() {
        let colors = cloneDeep(this.baseColors)
        return this.reverse ? colors.reverse() : colors
      },
      weight() {
        return this.max / this.grad
      },
      gradient() {
        return gradient(this.colors).rgb(this.grad, true)
      },
      percent() {
        return (this.value > this.max && this.max) || (this.value < 0 && 0) || this.value
      },
      color() {
        return this.gradient[Math.ceil(this.percent / this.weight) - 1]
      },
      gradientColor() {
        return (this.color && '#' + this.color.toHex()) || '#eee'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .progress {
    overflow: hidden;
    position: relative;
    text-align: center;
    height: 17px;

    .progress-bar {
      position: absolute;
      width: 0;
      height: 100%;
      max-width: 100%;
    }

    .progressbar-text {
      font-size: 11px;
      font-weight: 400;
      color: #fff;
      text-align: center;

      position: relative;
      text-shadow: #444 0 0 15px;
      line-height: 17px;

      padding: 0 15px;
    }
  }
</style>
