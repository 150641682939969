<template>
  <div>
    <progress-bar reverse :id="id" :value="item.days_in_work_percent || 0" :max="150">
      <span
        >{{ item.days_in_work || 0 }} / {{ item.days_in_production_plan || 0 }} дн. ({{
          item.days_in_work_percent || 0 | formatNumber('0,[00]')
        }}%)
      </span>
    </progress-bar>

    <popover :target="id">
      <div>
        <div>
          <b>Передан в производство: </b>
          <template v-if="item.project_start_date">
            {{ item.project_start_date | formatDate('DD.MM.YYYY') }}
          </template>
          <template v-else>-</template>
        </div>
        <div>
          <b>Закрытие проекта план: </b>
          <template v-if="item.deadline">
            {{ item.deadline | formatDate }}
          </template>
          <template v-else>-</template>
        </div>
        <div>
          <b>Закрытие проекта факт: </b>
          <template v-if="item.project_end_date">
            {{ item.project_end_date | formatDate }}
          </template>
          <template v-else>-</template>
        </div>
        <div>
          <b>Дней до закрытия план: </b>
          {{ item.days_to_deadline || '-' }}
        </div>
      </div>
    </popover>
  </div>
</template>

<script>
  import uniqid from 'uniqid'

  import Popover from '../../../components/PopoverAlive'
  import ProgressBar from '../../../components/ProgressBar'

  export default {
    name: 'Deadline',
    props: ['item'],
    components: {
      Popover,
      ProgressBar,
    },
    data() {
      return {
        id: uniqid('deadline-'),
      }
    },
  }
</script>

<style scoped></style>
