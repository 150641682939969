<template>
  <span v-if="color" class="lead-status" :style="{ 'background-color': hexToRgb(color, 0.5) }">
    {{ name }}
  </span>
</template>

<script>
  import hexRgb from 'hex-rgb'

  export default {
    name: 'StatusTag',
    props: ['name', 'color'],
    methods: {
      hexToRgb(value, alpha) {
        let rgb = hexRgb(value, { format: 'array' })

        rgb[3] = alpha

        return 'rgba(' + rgb.join() + ')'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .lead-status {
    display: inherit;

    font-size: 12px;
    font-weight: 400;

    border-radius: 3px;
    padding: 0 6px;

    color: rgba(0, 0, 0, 0.5);

    /*text-align: center;*/
  }
</style>
